<template>
  <small
    v-if="nameOnly"
    :class="{ 'text-italic': !control.name }"
    data-testid="name"
    >[{{ control.name || $t("unamed") }}]</small
  >
  <div class="control-header" v-else>
    <b v-if="control.synopticComponent">
      <small data-testid="title">{{ text }}</small>
      <template v-if="locker">
        <span
          :class="['fa fa-lock lock', { disabled: panelLocked }]"
          style="margin-left: 0.5em"
          v-if="control.locked || panelLocked"
          @click="toggleLock"
          data-testid="locked"
          :title="
            panelLocked ? $t('synoptic.panel_locked') : $t('synoptic.unlock')
          "
        ></span>
        <span
          class="fa fa-unlock lock"
          style="margin-left: 0.5em"
          v-else
          @click="toggleLock"
          data-testid="unlocked"
          :title="$t('synoptic.lock')"
        ></span>
      </template>
    </b>
    <div>
      <small :class="{ 'text-italic': !control.name }" data-testid="name"
        >[{{ control.name || $t("unamed") }}]</small
      >
    </div>
  </div>
</template>

<script>
import GalleryItems from "@/assets/dashboard/controls.json";
import { mapGetters } from "vuex";
export default {
  name: "ControlHeader",
  props: {
    control: {
      type: Object,
      required: true
    },
    nameOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    locker: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    text() {
      let ctrl = GalleryItems.find(
        (i) =>
          i.template.synopticComponent.componentName ==
          this.control.synopticComponent.componentName
      );
      return (
        (ctrl && ctrl.template.title) ||
        this.control.synopticComponent.componentName
      );
    },
    ...mapGetters("synoptic", { panelLocked: "isPanelLocked" })
  },
  methods: {
    toggleLock() {
      let control = JSON.parse(JSON.stringify(this.control));
      control.locked = !control.locked;
      this.$store.dispatch("synoptic/updateControl", {
        id: control.id,
        control
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.control-header {
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
}

.lock:hover {
  cursor: pointer;
  opacity: 0.9;
}

.lock.disabled {
  cursor: not-allowed;
}

.text-italic {
  font-style: italic;
}
</style>
